import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"

const Location = () => (
  <>
    <Seo title="熱銷據點" />
    <div
      className="p-10 pb-40 md:px-36 h-full"
      style={{ background: "#F3F3F3" }}
    >
      <div className="flex flex-col justify-center items-center mb-12 md:mb-16">
        <StaticImage
          src="../images/Desktop/05.LOGO_Gold@2x.png"
          width={76.88}
          alt="gold-logo"
          className="mb-4"
          placeholder="blurred"
        />
        <h1
          className="font-normal text-lg md:text-2xl"
          style={{ letterSpacing: "0.3em" }}
        >
          熱銷據點
        </h1>
      </div>
      <h2
        className="font-normal text-lg md:text-2xl"
        style={{ letterSpacing: "0.8em" }}
      >
        台北市
      </h2>
      <div
        className="mt-2 mb-7 md:mt-4 md:mb-10 bg-gold"
        style={{ width: 100, height: 1 }}
      ></div>
      <div className="md:grid md:gap-10 md:grid-cols-3">
        <div className="mb-11">
          <div className="flex items-center mb-4">
            <div
              className="bg-gold mr-3"
              style={{ transform: "skewX(-30deg)", width: 10, height: 14 }}
            />
            <h3 className="font-normal text-sm md:text-lg tracking-large whitespace-nowrap">
              布蘭登公爵
            </h3>
          </div>
          <p className="font-normal text-sm md:text-base mb-5 tracking-large">
            台北市松山區八德路四段760號8樓之一
          </p>
          <a
            href="tel:02-26532126"
            className="font-normal text-sm mb-5 tracking-large"
          >
            02-26532126
          </a>
        </div>
      </div>
      <h2
        className="font-normal text-lg md:text-2xl"
        style={{ letterSpacing: "0.8em" }}
      >
        新北市
      </h2>
      <div
        className="mt-2 mb-7 md:mt-4 md:mb-10 bg-gold"
        style={{ width: 100, height: 1 }}
      ></div>
      <div className="md:grid md:gap-10 md:grid-cols-3">
        <div className="mb-11">
          <div className="flex items-center mb-4">
            <div
              className="bg-gold mr-3"
              style={{ transform: "skewX(-30deg)", width: 10, height: 14 }}
            />
            <h3 className="font-normal text-sm md:text-lg tracking-large whitespace-nowrap">
              鴿子購物PGStore - 蘆洲長榮店
            </h3>
          </div>
          <p className="font-normal text-sm md:text-base mb-5 tracking-large">
            新北市蘆洲區長榮路379號
          </p>
          <p className="font-normal text-sm mb-5 tracking-large">
            營業時間：10：00-22：00
          </p>
        </div>
        <div className="mb-11">
          <div className="flex items-center mb-4">
            <div
              className="bg-gold mr-3"
              style={{ transform: "skewX(-30deg)", width: 10, height: 14 }}
            />
            <h3 className="font-normal text-sm md:text-lg tracking-large whitespace-nowrap">
              鴿子購物PGStore - 蘆洲徐匯店
            </h3>
          </div>
          <p className="font-normal text-sm md:text-base mb-5 tracking-large">
            新北市蘆洲區中山一路85號
          </p>
          <p className="font-normal text-sm mb-5 tracking-large">
            營業時間：10：00-22：00
          </p>
        </div>
        <div className="mb-11">
          <div className="flex items-center mb-4">
            <div
              className="bg-gold mr-3"
              style={{ transform: "skewX(-30deg)", width: 10, height: 14 }}
            />
            <h3 className="font-normal text-sm md:text-lg tracking-large whitespace-nowrap">
              鴿子購物PGStore - 板橋府中店
            </h3>
          </div>
          <p className="font-normal text-sm md:text-base mb-5 tracking-large">
            新北市板橋區中山路一段2號
          </p>
          <p className="font-normal text-sm mb-5 tracking-large">
            營業時間：10：00-22：00
          </p>
        </div>
      </div>
    </div>
  </>
)

export default Location
